// webpack automatically bundles all modules in your
// entry points. Those entry points can be configured
// in "webpack.config.js".
//
// Import deps with the dep name or local files with a relative path, for example:
//
//     import {Socket} from "phoenix"
//     import socket from "./socket"
//
import "phoenix_html"


import "./components/modal.js"
import "./components/sound_settings.js"
import "./components/achievements.js"
import "./components/hamburger.js"
import "./components/chessboard_settings.js"

let toggle = document.getElementById("toggle_dark_mode");
if (toggle != null) {
    toggle.onclick = function() {
        let theme = localStorage.getItem("data-theme") == "light" ? "dark" : "light";
        localStorage.setItem("data-theme", theme);
        document.documentElement.setAttribute('data-theme', theme);
    }
}
import {Socket} from "phoenix"
//import LiveSocket from "phoenix_live_view"
import { LiveSocket } from "phoenix_live_view"

let csrfToken = document.querySelector("meta[name='csrf-token']").getAttribute("content");
let liveSocket = new LiveSocket("/live", Socket, {params: {_csrf_token: csrfToken}});

// connect if there are any LiveViews on the page
liveSocket.connect()

// expose liveSocket on window for web console debug logs and latency simulation:
// >> liveSocket.enableDebug()
// >> liveSocket.enableLatencySim(1000)
window.liveSocket = liveSocket
